import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Time from '../Time'

const switchData = (data: { en: any; ka: any }, langKey: any) => {
    // eslint-disable-next-line no-unused-vars
    var posts: any
    switch (langKey) {
        case '':
            return (posts = data.en)
        case 'ka':
            return (posts = data.ka)
        default:
            return ' '
    }
}

class EventsRoll extends React.Component {
    posts: any
    static propTypes: {
        data: PropTypes.Requireable<
            PropTypes.InferProps<{
                allMarkdownRemark: PropTypes.Requireable<
                    PropTypes.InferProps<{
                        edges: PropTypes.Requireable<any[]>
                    }>
                >
            }>
        >
    }
    constructor(props: Readonly<{}>) {
        super(props)
        this.state = { url: '' }
    }

    getUrl() {
        const urlChecker =
            window.location.pathname !== '/events/'
                ? window.location.pathname
                : ''
        this.setState({ url: urlChecker })
    }

    componentDidMount() {
        this.getUrl()
    }

    toObject() {
        const dateForm = this.posts.map(
            ({ node: post }) => post.frontmatter.date
        )
        var rv = {}
        for (var i = 0; i < dateForm.length; ++i) rv[i] = dateForm[i]
        return rv
    }

    render() {
        const { data } = this.props
        const langKey = this.state.url.slice(1, 3)
        const { edges: posts } = switchData(data, langKey)
        return (
            <div className="container">
                <div className="columns is-multiline">
                    {posts &&
                        posts.map(({ node: post }) => (
                            <div className="column is-4" key={post.id}>
                                <div className="is-mobile is-transition is-bottom-border">
                                    <article className="tile is-child white-ter">
                                        <Link to={post.fields.slug}>
                                            <Img
                                                alt={post.frontmatter.imageAlt}
                                                fluid={
                                                    post.frontmatter.image
                                                        .childImageSharp.fluid
                                                }
                                            />
                                        </Link>
                                        <div className="has-article-content-padding">
                                            <div className="level is-mobile">
                                                <div className="level-left">
                                                    <p className="is-size-7 has-text-primary has-text-weight-bold">
                                                        {post.frontmatter.tags}
                                                    </p>
                                                </div>
                                                <div className="level-right">
                                                    <div className="is-size-7 has-text-primary has-text-weight-bold">
                                                        <Time
                                                            pubdate
                                                            langKey={
                                                                post.fields
                                                                    .langKey
                                                            }
                                                            date={
                                                                post.frontmatter
                                                                    .date
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                <Link
                                                    className="has-text-weight-bold is-size-5 has-article-title"
                                                    to={post.fields.slug}
                                                >
                                                    {post.frontmatter.title}
                                                </Link>
                                            </p>
                                            <p>
                                                <Link
                                                    className="content has-text-grey has-text-weight-medium is-size-6"
                                                    to={post.fields.slug}
                                                >
                                                    {post.excerpt}
                                                </Link>
                                            </p>
                                            <div className="level is-mobile has-margin-top">
                                                <div className="level-left">
                                                    <figure className="image is-32x32 is-margin-auto-zero">
                                                        <Img
                                                            className="is-image-radius-circle"
                                                            fluid={
                                                                post.frontmatter
                                                                    .authorImage
                                                                    .childImageSharp
                                                                    .fluid
                                                            }
                                                        />
                                                    </figure>
                                                    <p className="is-size-7 has-text-primary has-padding-left-one has-text-weight-bold">
                                                        {
                                                            post.frontmatter
                                                                .author
                                                        }
                                                    </p>
                                                </div>
                                                <div className="level-right">
                                                    <p className="is-size-7 has-text-primary has-text-weight-bold">
                                                        {post.timeToRead} min
                                                        read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        )
    }
}

EventsRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

// eslint-disable-next-line react/display-name
export default () => (
    <StaticQuery
        query={graphql`
            query EventsRollQuery {
                site {
                    siteMetadata {
                        title
                        languages {
                            langs
                            defaultLangKey
                        }
                    }
                }
                en: allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "blog-post" }
                            lang: { regex: "/(en|any)/" }
                            tags: { eq: "Events" }
                        }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 150)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                author
                                authorImage {
                                    childImageSharp {
                                        fluid(maxWidth: 32, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                                title
                                templateKey
                                date
                                lang
                                tags
                                imageAlt
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 500) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                            timeToRead
                        }
                    }
                }
                ka: allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "blog-post" }
                            lang: { regex: "/(ka|any)/" }
                        }
                    }
                ) {
                    edges {
                        node {
                            timeToRead
                            excerpt(pruneLength: 150)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                author
                                title
                                templateKey
                                date
                                lang
                                tags
                                authorImage {
                                    childImageSharp {
                                        fluid(maxWidth: 32, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                                imageAlt
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 500) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <EventsRoll data={data} />}
    />
)

/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import * as PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import SEO from '../components/SEO/SEO'
import { HTMLContent } from '../components/Content'
import { Carousel } from 'react-responsive-carousel'
import { FormattedMessage } from 'react-intl'
import Img from 'gatsby-image'
import Blogroll from '../components/BlogRoll'
import Eventsroll from '../components/EventsRoll'
import IOS from '../img/w_apple-dwn.png'
import Android from '../img/w_android-dwn.png'
import { isIOS, isAndroid, BrowserView } from 'react-device-detect'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

const HomePageTemplate = ({
    helmet,
    SliderOne,
    SliderTwo,
    SliderAppBG,
    SliderAppOne,
    SliderAppTwo,
    SliderAppThree,
}) => {
    const buttonRender = () => {
        if (isIOS) {
            return (
                <div className="columns application_button is-5">
                    <a
                        className="column is-6"
                        href="https://apps.apple.com/us/app/georgian-wine-finder/id1439021125"
                    >
                        <img src={IOS} alt="IOS Application" />
                    </a>
                </div>
            )
        }
        if (isAndroid) {
            return (
                <div className="columns application_button is-5">
                    <a
                        className="column is-6 margin_left"
                        href="https://play.google.com/store/apps/details?id=com.wine.finder"
                    >
                        <img src={Android} alt="Android Application" />
                    </a>
                </div>
            )
        }
        return (
            <div className="columns application_button is-5">
                <a
                    className="column is-6 margin_left"
                    href="https://play.google.com/store/apps/details?id=com.wine.finder"
                >
                    <img src={Android} alt="Android Application" />
                </a>
                <a
                    className="column is-6"
                    href="https://apps.apple.com/us/app/georgian-wine-finder/id1439021125"
                >
                    <img src={IOS} alt="IOS Application" />
                </a>
            </div>
        )
    }

    const textRender = () => {
        if (isAndroid) {
            return (
                <div className="application_text-box has-text-centered">
                    <a className="title is-3 black"
                        href="https://play.google.com/store/apps/details?id=com.wine.finder"
                    >
                                    <FormattedMessage id="home.app.title" />
                                    </a>
                                    <p className="subtitle is-6 black has-margin-top">
                                    <FormattedMessage id="home.app.subtitle" />
                                </p>
                            </div>
            )
        }
        if (isIOS) {
            return (
                <div className="application_text-box has-text-centered">
                    <a className="title is-3 black"
                        href="https://apps.apple.com/us/app/georgian-wine-finder/id1439021125"
                    >
                                    <FormattedMessage id="home.app.title" />
                                    </a>
                                    <p className="subtitle is-6 black has-margin-top">
                                    <FormattedMessage id="home.app.subtitle" />
                                </p>
                            </div>
            )
        }
        return (
            <div className="application_text-box has-text-centered">
            <Link className="title is-3 black"
                to="/app/"
            >
                            <FormattedMessage id="home.app.title" />
                            </Link>
                        <p className="subtitle is-6 black has-margin-top">
                            <FormattedMessage id="home.app.subtitle" />
                        </p>
                    </div>
        )
    }

    return (
        <>
            {helmet || ''}
            <section className="downloadApp">
                <div className="carusel-abs-content">
                    <div className="container layout_row abs-cont-col">
                        <h1 className="title is-1 abs-cont-title">
                            <FormattedMessage id="home.hero.title" />
                        </h1>
                        <p className="subtitle is-4 abs-cont-sub">
                            <FormattedMessage id="home.hero.subtitle" />
                        </p>
                        <div className="buttons are-medium is-hidden-touch">
                            <a
                                href="https://vinocorn.com/collections/all"
                                className="button is-medium is-green"
                            >
                                <FormattedMessage id="home.hero.shop" />
                            </a>
                            <Link
                                to="/blog/"
                                className="button is-medium is-primary"
                            >
                                <FormattedMessage id="home.hero.learn" />
                            </Link>
                            <Link
                                to="/app/"
                                className="button is-medium is-primary"
                            >
                                <FormattedMessage id="home.hero.discover" />
                            </Link>
                        </div>
                    </div>
                </div>
                <Carousel
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    autoPlay
                    infiniteLoop
                    interval={4000}
                    className="downloadApp_carusel"
                >
                    <div key="content-0">
                        <Img fluid={SliderOne} />
                    </div>
                    <div key="content-1">
                        <Img fluid={SliderTwo} />
                    </div>
                </Carousel>
                <div className="container layout_row">
                    <div className="columns is-paddingless downloadApp-column">
                        <div className="column is-paddingless is-7">
                            <div className="downloadApp-left">
                                <h2 className="title is-1 black">
                                    <FormattedMessage id="home.download.title" />
                                </h2>
                                <p className="subtitle is-5 black">
                                    <FormattedMessage id="home.download.subtitle" />
                                </p>
                            </div>
                        </div>
                        {buttonRender()}
                    </div>
                </div>

                <div className="Application_section">
                    <div className="columns is-paddingless downloadApp-column">
                        <div className="app_row">
                            <Img
                                className="app_row_bg"
                                fluid={SliderAppBG}
                                alt=""
                            />
                            <Carousel
                                showArrows={false}
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={false}
                                autoPlay
                                infiniteLoop
                                interval={2000}
                                className="Application_carusel"
                            >
                                <div>
                                    <Img fluid={SliderAppOne} />
                                </div>
                                <div>
                                    <Img fluid={SliderAppTwo} />
                                </div>
                                <div>
                                    <Img fluid={SliderAppThree} />
                                </div>
                            </Carousel>

                            {textRender()}
                        </div>
                    </div>
                </div>
                <div className="has-margin-top-3 container">
                    <h3 className="title  is-3 black">
                        <FormattedMessage id="home.events" />
                    </h3>
                    <Eventsroll />
                </div>
                <div className="has-margin-top-3 container">
                    <h3 className="title  is-3 black">
                        <FormattedMessage id="home.blog" />
                    </h3>
                    <Blogroll />
                </div>
            </section>
        </>
    )
}

HomePageTemplate.propTypes = {
    helmet: PropTypes.object,
    SliderOne: PropTypes.object,
}

class HomePage extends React.Component {
    render() {
        let data
        let dataMarkdown = []
        if (this.props.data !== null) {
            dataMarkdown = this.props.data.markdownRemark
            data = this.props.data
        }
        const jsonData = data.allArticlesJson.edges[0].node.articles
        const langKey = dataMarkdown.frontmatter.lang
        const { frontmatter } = data.markdownRemark

        return (
            <Layout
                className="content"
                data={this.props.data}
                jsonData={jsonData}
                location={this.props.location}
            >
                <SEO frontmatter={frontmatter} />
                <div>
                    <HomePageTemplate
                        helmet={
                            <Helmet
                                titleTemplate={`${dataMarkdown.frontmatter.title}`}
                            >
                                <title>{`${dataMarkdown.frontmatter.title}`}</title>
                                <meta
                                    name="description"
                                    content={`${dataMarkdown.frontmatter.description}`}
                                />
                            </Helmet>
                        }
                        title={dataMarkdown.frontmatter.title}
                        SliderOne={data.SliderOne.childImageSharp.fluid}
                        SliderTwo={data.SliderTwo.childImageSharp.fluid}
                        SliderAppBG={data.SliderAppBG.childImageSharp.fluid}
                        SliderAppOne={data.SliderAppOne.childImageSharp.fluid}
                        SliderAppTwo={data.SliderAppTwo.childImageSharp.fluid}
                        SliderAppThree={
                            data.SliderAppThree.childImageSharp.fluid
                        }
                        main={dataMarkdown.frontmatter.main}
                        contentComponent={HTMLContent}
                        content={dataMarkdown.html}
                        langKey={langKey}
                    />
                </div>
            </Layout>
        )
    }
}

HomePage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}

export default HomePage

export const pageQuery = graphql`
    query HomePageQuery($id: String!) {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        SliderOne: file(relativePath: { eq: "w_slider1.png" }) {
            childImageSharp {
                fluid(maxWidth: 1800, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        SliderTwo: file(relativePath: { eq: "w_slider2.png" }) {
            childImageSharp {
                fluid(maxWidth: 1800, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        SliderAppBG: file(relativePath: { eq: "w_circle.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        SliderAppOne: file(relativePath: { eq: "w_ap1.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        SliderAppTwo: file(relativePath: { eq: "w_ap2.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        SliderAppThree: file(relativePath: { eq: "w_ap3.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                id
                title
                description
                lang
            }
            fields {
                slug
            }
        }
    }
`
